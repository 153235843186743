@import url('../bootstrap/variables.less');

.rounded(@radius: 2px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

@topMargin: 30px;
@bottomMargin: 10px;

@handleActiveColor: @brand-primary;
@handleHoverColor: #fff;
@labelTextColor: @text-color;
@handleBgColor:  @link-color;
@handleInnerColor: @brand-primary;
@handleDisabledColor: #d8e0f3;
@limitLabelTextColor: @labelTextColor;
@barFillColor: @handleBgColor;
@barDisabledFillColor: #8b91a2;
@barNormalColor: #d8e0f3;

@ticksColor: @barNormalColor;
@selectedTicksColor: @barFillColor;
@ticksWidth: 10px;
@ticksHeight: 10px;
@ticksValuePosition: -30px;
@ticksLegendPosition: 24px;
@ticksValuePositionOnVertical: 24px;

@handleSize: 16px;
@handlePointerSize: 4px;
@bubblePadding: 1px 3px;
@barDimension: 2px;

@withLegendMargin: 40px;
