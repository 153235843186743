.wikitable {
	width: 100%;
	max-width: 100%;
	margin-bottom: @line-height-computed;
	// Cells
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: @table-cell-padding;
				line-height: @line-height-base;
				vertical-align: top;
				border-top: 1px solid @table-border-color;
				&.buttons {
					text-align: right;
					padding: 0;
					.btn {
						border-radius: 0;
						padding: @table-cell-padding @padding-base-horizontal;
					}
					.btn-group {
					  .btn + .btn,
					  .btn + .btn-group,
					  .btn-group + .btn,
					  .btn-group + .btn-group {
					    margin-left: 0;
					  }
					}
				}
			}
		}

		&:first-child > tr:first-child {
			> th,
			> td {
				&.buttons .btn {
					border-top-right-radius: @btn-border-radius-base;
				}
			}
		}
		&:last-child > tr:last-child {
			> th,
			> td {
				&.buttons .btn {
					border-bottom-right-radius: @btn-border-radius-base;
				}
			}
		}
	}
	// Bottom align for column headings
	> thead > tr > th {
		vertical-align: bottom;
		border-bottom: 2px solid @table-border-color;
	}
	// Remove top border from thead by default
	> caption + thead,
	> colgroup + thead,
	> thead:first-child {
		> tr:first-child {
			> th,
			> td {
				border-top: 0;
			}
		}
	}
	// Account for multiple tbody instances
	> tbody + tbody {
		border-top: 2px solid @table-border-color;
	}

	// Nesting
	.table {
		background-color: @body-bg;
	}
	> tbody > tr:nth-of-type(odd) {
		background-color: @table-bg-accent;
	}
}

.missing-link {
	color: @brand-danger;
}
