.star-rating {
	margin: 0;
	padding: 0;
	display: inline-block;
	white-space: nowrap;

	li {
		padding: 1px;
		font-size: 14px;
		text-shadow: .05em .05em @gray-darker;
		list-style-type: none;
		display: inline-block;
		cursor: pointer;
	}

	.star {
		color: @gray;

		&.filled {
			color: @link-color;
		}
	}
	.clear {
		color: @brand-danger;
	}
	&.readonly {
		li {
			cursor: auto;
		}

		.clear {
			display: none;
		}

		.star.filled {
			color: @link-color;
		}
	}
}

td.rating {
	width: 81+@table-cell-padding*2;
}
