//
// Tables
// --------------------------------------------------


table {
	background-color: @table-bg;
}
caption {
	padding-top: @table-cell-padding;
	padding-bottom: @table-cell-padding;
	color: @text-muted;
	text-align: left;
}
th {
	text-align: left;
}


// Baseline styles

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: @line-height-computed;
	// Cells
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: @table-cell-padding;
				line-height: @line-height-base;
				vertical-align: top;
				border-top: 1px solid @table-border-color;
				&.buttons {
					text-align: right;
					padding: 0;
					.btn {
						border-radius: 0;
						padding: @table-cell-padding @padding-base-horizontal;
					}
					.btn-group {
					  .btn + .btn,
					  .btn + .btn-group,
					  .btn-group + .btn,
					  .btn-group + .btn-group {
					    margin-left: 0;
					  }
					}
				}
			}
		}

		&:first-child > tr:first-child {
			> th,
			> td {
				&.buttons .btn {
					border-top-right-radius: @btn-border-radius-base;
				}
			}
		}
		&:last-child > tr:last-child {
			> th,
			> td {
				&.buttons .btn {
					border-bottom-right-radius: @btn-border-radius-base;
				}
			}
		}
	}
	// Bottom align for column headings
	> thead > tr > th {
		vertical-align: bottom;
		border-bottom: 2px solid @table-border-color;
	}
	// Remove top border from thead by default
	> caption + thead,
	> colgroup + thead,
	> thead:first-child {
		> tr:first-child {
			> th,
			> td {
				border-top: 0;
			}
		}
	}
	// Account for multiple tbody instances
	> tbody + tbody {
		border-top: 2px solid @table-border-color;
	}

	// Nesting
	.table {
		background-color: @body-bg;
	}
}


// Condensed table w/ half padding

.table-condensed {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				padding: @table-condensed-cell-padding;
			}
		}
	}
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
	border: 1px solid @table-border-color;
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				border: 1px solid @table-border-color;
			}
		}
	}
	> thead > tr {
		> th,
		> td {
			border-bottom-width: 2px;
		}
	}
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
	> tbody > tr:nth-of-type(odd) {
		background-color: @table-bg-accent;
	}
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
	> tbody > tr:hover {
		background-color: @table-bg-hover;
	}
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
	position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
	float: none;
	display: table-column;
}
table {
	td,
	th {
		&[class*="col-"] {
			position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
			float: none;
			display: table-cell;
		}
	}
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(active; @table-bg-active);
.table-row-variant(success; @state-success-bg);
.table-row-variant(info; @state-info-bg);
.table-row-variant(warning; @state-warning-bg);
.table-row-variant(danger; @state-danger-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

	@media screen and (max-width: @screen-xs-max) {
		width: 100%;
		margin-bottom: (@line-height-computed * 0.75);
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid @table-border-color;

		// Tighten up spacing
		> .table {
			margin-bottom: 0;

			// Ensure the content doesn't wrap
			> thead,
			> tbody,
			> tfoot {
				> tr {
					> th,
					> td {
						white-space: nowrap;
					}
				}
			}
		}

		// Special overrides for the bordered tables
		> .table-bordered {
			border: 0;

			// Nuke the appropriate borders so that the parent can handle them
			> thead,
			> tbody,
			> tfoot {
				> tr {
					> th:first-child,
					> td:first-child {
						border-left: 0;
					}
					> th:last-child,
					> td:last-child {
						border-right: 0;
					}
				}
			}

			// Only nuke the last row's bottom-border in `tbody` and `tfoot` since
			// chances are there will be only one `tr` in a `thead` and that would
			// remove the border altogether.
			> tbody,
			> tfoot {
				> tr:last-child {
					> th,
					> td {
						border-bottom: 0;
					}
				}
			}

		}
	}
}

.table-items {
	@media only screen and (max-width: @screen-xs-max) {
		display: block;
		/* Force table to not be like tables anymore */
		thead {
			display: block;
			tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
		}

		tbody {
			display: block;
			tr {
				display: block;
				td {
					display: block;
					border: none;
					border-top: 1px solid @table-border-color;
					position: relative;
					padding-left: 30%;
					white-space: normal;
					text-align:left;

					&:before {
						position: absolute;
						top: @table-cell-padding;
						left: @table-cell-padding;
						width: 25%;
						padding-right: 10px;
						white-space: nowrap;
						text-align: left;
						font-weight: bold;
						content: attr(data-title);
					}
				}
			}
		}
	}

	@media (max-width: (@screen-xs-max/2)) {
		tbody {
			tr {
				overflow: hidden;
				td {
					padding: @table-cell-padding;
					&:before {
						width: auto;
						left: 0;
						top: 0;
						padding: 0 0 @table-cell-padding 0;
						position: relative;
						display: block;
						clear: both;
						width: auto;
					}
				}
			}
		}
	}
}
