.player {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: @brand-primary;
	color: @text-color;
	padding: 2px 0;
	z-index: 100;

	.currentSong {
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0 4px;
		display: block;
		.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
		.button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
		  -webkit-touch-callout: none; /* iOS Safari */
		    -webkit-user-select: none; /* Safari */
		     -khtml-user-select: none; /* Konqueror HTML */
		       -moz-user-select: none; /* Firefox */
		        -ms-user-select: none; /* Internet Explorer/Edge */
		            user-select: none; /* Non-prefixed version, currently
		                                  supported by Chrome and Opera */
	}

	.playlist {
		position: fixed;
		bottom: 0;
		max-width: 100%;
		left: 0;
		right: 0;
		top: 0;
		.content {
			background: @brand-primary;
			margin: 20px 20px 40px 20px;
			height: 100%;
			-webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
			box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
			z-index: 110;
		}
	}

	.buttons {
		width: 115px;
		padding-top: 8px;
		float: left;
	}

	.seekBarWrap {
		margin-left: 115px;

		.seekBar {
			display: block;
			padding: 12px 0 9px 0;
			position: relative;
			&:after {
				position: absolute;
				top: 50%;
				margin-top: -1px;
				content: "";
				display: block;
				background: darken(@brand-primary, 10%);
				height: 4px;
				left: 10px;
				right: 10px;
				z-index: 104;
			}

			.cursor {
				background: @gray-lighter;
				height: 20px;
				width: 20px;
				border-radius: 10px;
				position: relative;
				left: 0%;
				z-index: 110;
			}
			.timePopup {
				display: none;
			}
			.timePopupArrow {
				display: none;
			}

			b {
				position: absolute;
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin: -4px 0 0 4px;
				top: 50%;
				background: darken(@brand-primary, 10%);
				z-index: 105;
			}
		}
	}

	.seekBar:hover, .dragging .seekBar {
		.timePopup {
			display: block;
			position: absolute;
			left: -30px;
			right: -30px;
			bottom: 32px;
			height: 40px;
			background: lighten(@brand-primary, 20%);
			border-radius: 4px;
			text-align: center;
			line-height: 40px;
			overflow: hidden;
			white-space: nowrap;
		}
		.timePopupArrow {
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid lighten(@brand-primary, 20%);
			bottom: 22px;
		}
		.cursor {
			background: lighten(@brand-primary, 20%);
		}
	}
}

tr.song {
	cursor: pointer;

	&.playing, &:nth-of-type(odd).playing {
		background: @gray-darker;
	}
	&.playing:hover, &:nth-of-type(odd).playing:hover {
		background: lighten(@gray-darker, 3%);
	}
	&.favorite > td.heart-button > .buttons-hover-row {
		visibility: visible;
	}

	> td > .buttons-hover-row {
		visibility: hidden;
		&.open {
			visibility: visible;
		}

		.dropdown-menu {
			margin: -1px 0 0 0;
			left: auto;
			right: 0;
		}

		button.btn-default {
			background: transparent;
			border: 0;

			&.heart .glyphicon-heart {
				color: #f77;
			}
		}
	}

	&:hover > td > .buttons-hover-row {
		> * {
			visibility: visible;
		}
	}

}

.play-queue td {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.volumeButton {
	position: relative;
	.volPopup {
		display: none;
		background: darken(@brand-primary, 10%);
		border-radius: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 120px;

		.cursor {
			background: @gray-lighter;
			margin: 0 auto;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			position: relative;
			&:hover {
				background: lighten(@brand-primary, 10%);
			}
		}
	}
	&:hover .volPopup {
		display: block;
	}
	&.dragging .volPopup {
		.cursor {
			background: darken(@brand-primary, 10%);
		}
	}
}
.dragging .volumeButton {
	.volPopup {
		display: block;
		.cursor {
			background: lighten(@brand-primary, 10%);
		}
	}
}
